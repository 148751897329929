import {
    IonContent,
    IonRow,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonItem,
    IonIcon,
    IonButtons,
    IonBackButton,
    IonDatetime,
    IonText,
    IonButton,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import Auth from "../../components/Auth";
import Moment from "moment";
import {
    calendar, refreshOutline,
} from "ionicons/icons";
import DataTable from "react-data-table-component";


interface datasauce {
    PK_user: number;
    username: string;
    temp: number;
    cough: number;
    throat: number;
    breath: number;
    diarrhea: number;
    soreeyes: number;
    covidbuddy: number;
    rashes: number;
    isconsult: number;
    iscounsel: number;
}


const NameSymptoms: React.FC<any> = ({ history }) => {

    const [DataSource, setDataSource] = useState<datasauce[]>([]);

    const Datenow = Moment(new Date()).format("YYYY-MM-DD");
    const [Datefrom, setDatefrom] = useState<string>(Datenow);
    const customStyles = {
        rows: {
            style: {
                minHeight: "72px", // override the row height
                fontSize: "8",
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",
                fontSize: "12",
                fontWeight: "Bold",
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",
            },
        },
    };


    const columns = [
        {
            name: "Employee Name",
            selector: "fullname",
            sortable: true,
            wrap: true,
        },
        {
            name: "Section",
            selector: "sectcode",
            sortable: true,
            right: true,
        },
        {
            name: "Temp",
            selector: "temp",
            sortable: true,
            right: true,
            wrap: true,
        },
    ];
    function aaddress(holder: any) {
        setDatefrom(holder)
        fetchall()
    }
    function fetchall() {
        Promise.all([
            fetch(Auth.globalwebhost + "/monitoringapp/namesymptoms", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    tablename: Auth.nametable,
                    isok: 1,
                    Dateto: Moment(Datefrom).format("YYYY-MM-DD"),
                }),
            }),
        ])
            .then(([res1]) =>
                Promise.all([res1.json()])
            )
            .then(([responseJson1]) => {
                setDataSource(responseJson1);
                console.log(responseJson1)
            });
    }

    useEffect(() => {
        fetchall();
        // eslint-disable-next-line        
    }, []);


    const sympholdcallback = () => {
        history.replace({
            pathname: `/last10symp`,
        });
    };

    return (
        <IonPage>
            <IonHeader className="ion-no-boarder">
                <IonToolbar className="tabcolor">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/summaryreport" />
                    </IonButtons>
                    <IonRow>
                        <IonTitle className="ion-text-center"> List of Employees</IonTitle>
                    </IonRow>
                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding">
                <IonGrid>
                    <IonTitle>{Auth.nametable}</IonTitle>
                    <IonItem >
                        <IonText>Date:</IonText>
                        <IonIcon slot="start" icon={calendar} />
                        <IonDatetime
                            displayFormat="YYYY-MM-DD"
                            value={Datefrom}
                            doneText={"SET"}
                            // onIonChange={(e) => setDatefrom(e.detail.value!)}                                  
                            onIonChange={(e) => aaddress(e.detail.value!)}


                        ></IonDatetime>

                        <IonButton
                            shape="round"
                            type="submit"
                            color="success"
                            onClick={fetchall}
                        >
                            Load
                                        <IonIcon slot="start" icon={refreshOutline} />
                        </IonButton>
                    </IonItem>

                    <DataTable
                        data={DataSource}
                        columns={columns}
                        pagination={true}
                        highlightOnHover={true}
                        noHeader={true}
                        customStyles={customStyles}
                        expandableInheritConditionalStyles={true}
                        direction="auto"
                        onRowClicked={(e) => {
                            Auth.symphold(sympholdcallback, e.PK_user);
                        }}

                    />
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default NameSymptoms;
