class Auth {
  constructor() {
    this.authenticated = false;
    this.PK_user = 0;
    this.Password = "";
    this.globalwebhost = "https://www.spcmcph.com";
    this.globalwebhost = "https://api.spcmc.online";
   
    this.is_admin = "";
    this.FK_company = "";
    this.is_superadmin = "";
    this.localwebhost = "";
    this.isparcc = 0;
    this.ispkuser = 0;
    this.FK_user = 0;
    this.nametable = "";
    this.sympkuser = 0;
  }
  login(cb, username, password) {
    fetch(this.globalwebhost + "/monitoringapp/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.PK_user === 0) {
          this.authenticated = false;
          alert("Login error: " + responseJson.message);
        } else {
          this.authenticated = true;
          this.PK_user = responseJson.PK_user;
          this.password = responseJson.Password;
          this.is_admin = responseJson.is_admin;
          this.FK_company = responseJson.FK_company;
          this.is_superadmin = responseJson.is_superadmin;
          // this.globalwebhost = globalwebhost;
          cb();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  globalcompany(cb, newcompany) {
    this.FK_company = newcompany;
    cb();
  }
  tempcompany(cb, tempcompany) {
    this.FK_company = tempcompany;
    cb();
  }
  logout(cb) {
    this.authenticated = false;
    this.PK_user = 0;
    cb();
  }
  isAuthenticated() {
    return this.authenticated;
  }

  cctest(cb, parmscc) {
    this.isparcc = parmscc;
    cb();
  }

  cchold(cb, PK_usersched, FK_user) {
    this.ispkuser = PK_usersched;
    this.FK_user = FK_user;
    cb();
  }

  nsymptoms(cb, nametable ){
    this.nametable = nametable;
    cb();
  }

  symphold(cb, PK_user) {
    this.sympkuser = PK_user;  
    cb();
  }


}

export default new Auth();
