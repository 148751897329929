import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  IonItem,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonTextarea,
  IonButton,
  IonIcon,
  IonModal,
  IonButtons,
  IonBackButton,
} from "@ionic/react";

import React, { useState } from "react";
import { logIn } from "ionicons/icons";
import Schedholder from "../../components/Schedholder";

const Medicalconsult: React.FC<any> = ({ history, location }) => {
  const [isConsellingModalVisible, setIsConsellingModalVisible] = useState(
    false
  );
  // const [isConsultModalVisible, setisConsultModalVisible] = useState(false);
  const [ainputs, setainputs] = useState("");

  const schedtype = "0";
  const input = ainputs;
  const hr = false;
  const trans = false;
  const house = false;
  const safe = false;
  const psych = false;

  function answerCounsel() {
    setIsConsellingModalVisible(false);
    history.replace({
      pathname: `/mdashboard`,
    });
  }

  function RenderModals() {
    return (
      <IonModal isOpen={isConsellingModalVisible}>
        <IonHeader>
          <IonToolbar className="tabcolor">
            <IonTitle>Medical Consulatation</IonTitle>
            <IonRow slot="end">
              <IonButton
                className="ion-margin"
                type="submit"
                color="light"
                onClick={() => answerCounsel()}
              >
                Close
              </IonButton>
            </IonRow>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow>
              <p>
                You will be notified once an appointment has been confirmed.
              </p>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    );
  }

  function loadconfirmation() {
    const cb = () => {
      history.push({
        pathname: `/sconsult`,
        // state: clvars,
      });
    };

    Schedholder.saveschedule(
      cb,
      schedtype,
      input,
      hr,
      trans,
      house,
      safe,
      psych
    );
    // const clvars = {
    //   schedtype: 0,
    //   input: ainputs,
    //   hr: false,
    //   trans: false,
    //   house: false,
    //   safe: false,
    //   psych: false,
    // };

    //this.setState({ isConsultModalVisible: true });
    // this.props.navigation.navigate("schedconsult", { PK_user: PK_user, password: password, schedtype: 0, input: ainputs, clvars: clvars });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/smenu" />
          </IonButtons>
          <IonTitle>Medical Consulatation:</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed">
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonLabel position="floating">
                Why do you need a medical consult? Please state reasons:
              </IonLabel>
              <IonItem>
                <IonTextarea
                  value={ainputs}
                  onIonChange={(e) => setainputs(e.detail.value!)}
                ></IonTextarea>
              </IonItem>
              <IonButton
                className="ion-margin"
                type="submit"
                color="success"
                onClick={() => loadconfirmation()}
              >
                Submit
                <IonIcon slot="start" icon={logIn} />
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        {RenderModals()}
      </IonContent>
    </IonPage>
  );
};

export default Medicalconsult;
