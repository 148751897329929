import {
    IonContent,
    IonRow,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonCol,
    IonItem,
    IonIcon,
    IonButton,
    IonButtons,
    IonBackButton,
    IonDatetime,
    IonList,
    IonCard,
    IonText,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import Auth from "../../components/Auth";
import Moment from "moment";
import {
    calendar,
    cloudDoneOutline,
} from "ionicons/icons";
import MUIDataTable from "mui-datatables";


interface datasauce {
    PK_user: number;
    username: string;
    temp: number;
    cough: number;
    throat: number;
    breath: number;
    diarrhea: number;
    soreeyes: number;
    covidbuddy: number;
    rashes: number;
    isconsult: number;
    iscounsel: number;
}


const GenerateReports: React.FC<any> = ({ history }) => {

    const [DataSource, setDataSource] = useState<datasauce[]>([]);

    const Datenow = Moment(new Date()).format("YYYY-MM-DD");
    const [Datefrom, setDatefrom] = useState<string>(Datenow);
    const [Dateto, setDateto] = useState<string>(Datenow);
    function fetchall() {

        if (Datefrom <= Dateto) {
            Promise.all([
                fetch(Auth.globalwebhost + "/monitoringapp/GenerateReports", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        FK_company: Auth.FK_company,
                        Datefrom: Moment(Datefrom).format("YYYY-MM-DD"),
                        Dateto: Moment(Dateto).format("YYYY-MM-DD"),
                    }),
                }),
            ])
                .then(([res1]) =>
                    Promise.all([res1.json()])
                )
                .then(([responseJson1]) => {
                    setDataSource(responseJson1);
                });

        } else {
            alert("Invalid Date Please Check")
        }
    }

    useEffect(() => {
        fetchall();
        // eslint-disable-next-line
    }, []);



    const columns12 = [
        {
            name: "username",
            label: "Username",
            options: {
                filter: false,
                sort: false,
                display: false,
                viewColumns: false,
            },
        },
        {
            name: "fullname",
            label: "EmployeeName",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "sectcode",
            label: "Section",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "DateNow",
            label: "DateofSymptoms",
            options: {
                filter: true,
                sort: true,
            },
        },

        {
            name: "temp",
            label: "Temperature",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "cough",
            label: "Cough",
            options: {
                filter: true,
                sort: true,
            },
        },

        {
            name: "throat",
            label: "Sore Throat",
            options: {
                filter: true,
                sort: true,
            },
        },

        {
            name: "breath",
            label: "Breath",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "diarrhea",
            label: "Diarrhea",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "soreeyes",
            label: "Soreeyes",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "rashes",
            label: "Rashes",
            options: {
                filter: true,
                sort: true,
            },
        },

        {
            name: "colds",
            label: "Colds",
            options: {
                filter: true,
                sort: true,
            },
        },

        {
            name: "covidbuddy",
            label: "ETCP",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "ssmell",
            label: "SofSmell",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "staste",
            label: "SofTaste",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "pmall",
            label: "Mall",
            options: {
                filter: true,
                sort: true,
            },
        },

        {
            name: "ppaleng",
            label: "Palengke",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "psimba",
            label: "Simbahan",
            options: {
                filter: true,
                sort: true,
            },
        },

        {
            name: "ptbus",
            label: "Bus",
            options: {
                filter: true,
                sort: true,
            },
        },

        {
            name: "ptjeep",
            label: "Jeep",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "pttri",
            label: "Tricycle",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "yptext",
            label: "Other Places",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "visittex",
            label: "Other Visitors",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const Options2 = {
        filterType: "dropdown",
        print: false,
        sort: true,
        sortDescFirst: true,
        sortThirdClickReset: true,
        selectableRowsOnClick: true,


    };

    return (
        <IonPage>
            <IonHeader className="ion-no-boarder">
                <IonToolbar className="tabcolor">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/summaryreport" />
                    </IonButtons>
                    <IonRow>
                        <IonTitle className="ion-text-center"> Generate Symptoms Report </IonTitle>
                    </IonRow>
                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding">
                <IonGrid className="ion-margin ion-no-padding">
                    <IonList>
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonItem>
                                        <IonText>From Date:</IonText>
                                        <IonIcon slot="start" icon={calendar} />
                                        <IonDatetime
                                            displayFormat="YYYY-MM-DD"
                                            value={Datefrom}
                                            pickerFormat="YYYY-MM-DD"
                                            doneText={"SET"}
                                            onIonChange={(e) => setDatefrom(e.detail.value!)}
                                        ></IonDatetime>
                                    </IonItem>
                                </IonCard>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonItem>
                                        <IonText>To Date:</IonText>
                                        <IonIcon slot="start" icon={calendar} />
                                        <IonDatetime
                                            displayFormat="YYYY-MM-DD"
                                            pickerFormat="YYYY-MM-DD"
                                            value={Dateto}
                                            doneText={"SET"}
                                            onIonChange={(e) => setDateto(e.detail.value!)}
                                        ></IonDatetime>

                                    </IonItem>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton
                                    shape="round"
                                    type="submit"
                                    color="success"
                                    onClick={fetchall}
                                >
                                    Generate
                                        <IonIcon slot="start" icon={cloudDoneOutline} />
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonList>
                    <MUIDataTable
                        title={"List of Employees with Symptoms from " + Moment(Datefrom).format("YYYY-MM-DD") + " to " + Moment(Dateto).format("YYYY-MM-DD")}
                        data={DataSource}
                        columns={columns12}
                        options={Options2}
                    />
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default GenerateReports;
