import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  IonLabel,
  IonRow,
  IonButton,
  IonIcon,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { medkit } from "ionicons/icons";
import Auth from "../../components/Auth";
import Schedholder from "../../components/Schedholder";

const SchedConsult: React.FC<any> = ({ history, location }) => {
  const [message, setmessage] = useState("Saving schedule request...");

  useEffect(() => {
    doschedule();
    // return function () {
    //   console.log(message);
    // };
  });

  function doschedule() {
    console.log("LOAD");
    fetch(Auth.globalwebhost + "/monitoringapp/newsched", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        PK_user: Auth.PK_user,
        // password: Auth.password,
        schedtype: Schedholder.schedtype,
        input: Schedholder.input,

        hr: Schedholder.hr,
        trans: Schedholder.trans,
        house: Schedholder.house,
        safe: Schedholder.safe,
        psych: Schedholder.psych,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.result === 0) {
          setmessage(
            "You will be scheduled for a consulation. You will be notified once an appointment has been confirmed."
          );
        } else {
          setmessage(responseJson.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    //this.props.navigation.replace("MonitoringSheet", { PK_user: this.state.PK_user, password: this.state.password });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonTitle> Schedule for Consultation </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="ion-text-left ion-margin-start ion-padding">
          <IonLabel position="floating">{message}</IonLabel>
          <IonRow>
            <IonCol>
              <IonButton
                className="ion-margin"
                type="submit"
                color="success"
                expand="full"
                shape="round"
                onClick={() =>
                  history.replace({
                    pathname: `/mdashboard`,
                  })
                }
              >
                Ok
                <IonIcon slot="start" icon={medkit} />
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SchedConsult;
