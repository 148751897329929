import {
  IonContent,
  IonRow,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonIcon,
  IonText,
  IonInput,
  IonButton,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { personAddSharp } from "ionicons/icons";
import Auth from "../../components/Auth";

interface Message {
  PK_tempcompany: number;
  compname: string;

  contactperson: string;
  contactno: number;
  emailadd: string;
  address: string;

  compicon: string;
}
const UpdateCompanydetails: React.FC<any> = ({ history, match }) => {
  const [compname, setcompname] = useState("");
  const [contactp, setcontactp] = useState("");
  const [contactn, setcontactn] = useState("");
  const [emailadd, setemailadd] = useState("");
  const [address, setaddress] = useState("");
  const [compicon, setcompicon] = useState({ preview: "", raw: "" });

  const [DataSource, setDataSource] = useState<Message[]>([]);
  // const [showAlert1, setShowAlert1] = useState(false);

  useEffect(() => {
    fetchcominfo();
    // return function () {};
  }, []);

  function fetchcominfo() {
    // setisLoading(true);

    fetch(Auth.globalwebhost+"/monitoringapp/loadcompany", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        PK_tempcompany: Auth.FK_company ? Auth.FK_company : 1,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);

        setDataSource(responseJson);

        // console.log("Successfully load");
      });
  }

  const updatecompy = async (e: any) =>  {
    const fetchAsBlob = (url: any) =>
      fetch(url).then((response) => response.blob());
    const convertBlobToBase64 = (blob: Blob) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });

    const blobUrl = compicon.preview;

    const blob64 = await fetchAsBlob(blobUrl)
      .then(convertBlobToBase64)
      .then((base64: any) => {
        return base64;
      });

    console.log(blob64);

    fetch(Auth.globalwebhost+"/monitoringapp/updatecompanydetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        compname: compname,
        contactp: contactp,
        contactn: contactn,
        emailadd: emailadd,
        address: address,
        compicon: blob64,
        PK_tempcompany: Auth.FK_company,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        console.log("Successfully Inserted");
        history.push({
          pathname: `/admindashboard`,
        });
      });
  };
  return (
    <IonPage>
      {DataSource.map((m) => (
        <IonContent key={m.PK_tempcompany}>
          <IonToolbar className="tabcolor">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/admindashboard" />
            </IonButtons>
            <IonRow>
              <IonTitle className="ion-text-center">
                {" "}
                Update Company Details: {m.compname}
              </IonTitle>
            </IonRow>
          </IonToolbar>

          <IonGrid className="ion-no-margin ion-padding">
            <IonList lines="full">
              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  Company Name <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  required
                  type="text"
                  clearInput
                  // onIonChange={(e: any) => setcompname(e.target.value)}
                  onIonInput={(e: any) => setcompname(e.target.value)}
                  placeholder={m.compname}
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  Contact Person: <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  required
                  type="text"
                  clearInput
                  onIonInput={(e: any) => setcontactp(e.target.value)}
                  placeholder={m.contactperson}
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  Contact No: <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  required
                  type="number"
                  clearInput
                  onIonChange={(e: any) => setcontactn(e.target.value)}              
                  // placeholder={m.contactno.toString()}
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">
                  {" "}
                  Email Address <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  required
                  type="email"
                  clearInput
                  onIonInput={(e: any) => setemailadd(e.target.value)}
                  placeholder={m.emailadd}
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Address</IonLabel>
                <IonInput
                  required
                  type="text"
                  clearInput
                  onIonInput={(e: any) => setaddress(e.target.value)}
                  placeholder={m.address}
                ></IonInput>
              </IonItem>

              <IonItem>
                <IonLabel position="fixed">Add Logo:</IonLabel>
                <input
                  type="file"
                  src=""
                  // style={{ display: "none" }}
                  onChange={(e: any) =>
                    setcompicon({
                      preview: URL.createObjectURL(e.target.files[0]),
                      raw: e.target.files[0],
                    })
                  }
                />
              </IonItem>
            </IonList>
            <IonCol>
              <IonRow>
                <img
                  src={compicon.preview ? compicon.preview : m.compicon}
                  alt="COMPANY LOGO"
                  width="300"
                  height="300"
                />
              </IonRow>
            </IonCol>

            <IonCol>
              <IonRow>
                <IonButton
                  expand="block"
                  type="submit"
                  color="success"
                  onClick={updatecompy}
                >
                  Update Company Details
                  <IonIcon slot="start" icon={personAddSharp} />
                </IonButton>
              </IonRow>
            </IonCol>
          </IonGrid>
          {/* <IonAlert
          isOpen={showAlert1}
          onDidDismiss={() => setShowAlert1(false)}
          cssClass='my-custom-class'
          header={'Do you want to Update Company Details'}          
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                history.replace({
                  pathname: `/mdashboard`,
                });
              }
            },
            {
              text: 'OK',              
              handler: () => {
               
            
              }          
            }            
       
          ]}
        /> */}
        </IonContent>
      ))}
    </IonPage>
  );
};

export default UpdateCompanydetails;
