import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  IonBackButton,
  IonRow,
  IonButtons,
} from "@ionic/react";
import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import Auth from "../../components/Auth";

interface Request {
  FK_user: number;
  FK_company: number;
  username: string;
  datetemp: string;
  tstamp: string;
  is_active: number;
  isconsult: number;
  iscounsel: number;
  PK_usersched: number;
}

const columns = [
  {
    name: "Employee Name",
    selector: "fullname",
    sortable: true,
    wrap: true,
  },
  {
    name: "Section",
    selector: "sectcode",
    sortable: true,
    right: true,
  },
  {
    name: "Date",
    selector: "dateadded",
    sortable: true,
    right: true,
  },
  {
    name: "Schedule Type",
    selector: "schedtypes",
    sortable: true,
    right: true,
    wrap: true,
  },
];

const Requests: React.FC<any> = ({ history }) => {
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
        fontSize: "8",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "12",
        fontWeight: "Bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const [DataSource, setDataSource] = useState<Request[]>([]);

  function fetchall() {
    if (Auth.isparcc === 1) {
      fetch(Auth.globalwebhost + "/monitoringapp/schedtype1", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          FK_company: Auth.FK_company,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setDataSource(responseJson);
        });
    } else if (Auth.isparcc === 2) {
      fetch(Auth.globalwebhost + "/monitoringapp/schedtype0", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          FK_company: Auth.FK_company,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setDataSource(responseJson);
        });
    }
  }

  const ccholdcallback = () => {
    history.replace({
      pathname: `/requestinfo`,
    });
  };

  useEffect(() => {
    fetchall();
  }, []);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/SummaryReport" />
          </IonButtons>
          <IonTitle className="ion-margin">Requests </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <DataTable
                data={DataSource}
                columns={columns}
                pagination={true}
                highlightOnHover={true}
                noHeader={true}
                customStyles={customStyles}
                expandableInheritConditionalStyles={true}
                direction="auto"
                onRowClicked={(e) => {
                  Auth.cchold(ccholdcallback, e.PK_usersched, e.FK_user);
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Requests;
