import {
  IonContent,
  IonIcon,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
} from "@ionic/react";
import React from "react";

import {personCircle } from "ionicons/icons";
const PatientDetails: React.FC = () => {
  return (
    <IonPage>
      <IonHeader className="ion-no-boarder">
        <IonToolbar className="tabcolor">            
          <IonTitle>Retrieve to the List</IonTitle>   
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonLabel>Room:</IonLabel>
          <IonInput name="Room" type="text"></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel>Patient:</IonLabel>
          <IonInput name="Patient" type="text"></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel>Age:</IonLabel>
          <IonInput name="Age" type="number" min="0"></IonInput>
        </IonItem>

        <IonButton type="submit" color="primary">
          New Request
          <IonIcon slot="start" icon={personCircle} />
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default PatientDetails;
