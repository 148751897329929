import {
  IonContent,
  IonRow,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonIcon,
  IonText,
  IonInput,
  IonButton,
  IonButtons,
  IonBackButton,
  IonAlert,
  IonCheckbox,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { personAddSharp } from "ionicons/icons";
import Auth from "../../components/Auth";

const AddCompanyUser: React.FC<any> = ({ history, match, location }) => {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);

  const [showaddcomp, setshowaddcomp] = useState(false);
  const [checked, setChecked] = useState(false);
  const [disablecheck, setdisablecheck] = useState(false);

 
  function addcompanyuser() {
    fetch(Auth.globalwebhost + "/monitoringapp/addcompanyuser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
        FK_company: Auth.FK_company,
        FK_userinsert: Auth.PK_user,
        is_admin: checked,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        console.log("Successfully Inserted");
      });
  }
  useEffect(() => {
    // console.log(location.search);
    if (Auth.is_admin === "1") {
      setshowaddcomp(true);

      if (Auth.is_superadmin === "1") {
        setdisablecheck(false);
        // setChecked(true);
      } else {
        setdisablecheck(true);
        // setChecked(false);
      }
      console.log(checked);
    } else {
      setshowaddcomp(false);
    }
  }, [checked]);
  return (
    <IonPage>
      <IonHeader className="ion-no-boarder">
        <IonToolbar className="tabcolor">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/admindashboard" />
          </IonButtons>
          <IonRow>
            <IonTitle className="ion-text-center">
              {" "}
              Create Company User
            </IonTitle>

            {/* <IonItem className="tabcolor">
                    <IonIcon onClick={UserLoginFunction} icon={notificationsSharp} />
                  </IonItem> */}
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <IonList lines="full" className="ion-no-margin ion-no-padding">
          <IonItem>
            <IonLabel position="stacked">
              Username <IonText color="danger">*</IonText>
            </IonLabel>
            <IonInput
              required
              type="text"
              onIonChange={(e: any) => setusername(e.target.value)}
              clearInput
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              {" "}
              Password <IonText color="danger">*</IonText>
            </IonLabel>
            <IonInput
              required
              type="password"
              clearInput
              onIonChange={(e: any) => setpassword(e.target.value)}
            ></IonInput>
          </IonItem>
          <div style={{ display: showaddcomp ? "block" : "none" }}>
            <IonItem>
              <IonLabel>is admin:</IonLabel>
              <IonCheckbox
                slot="start"
                checked={checked}
                onIonChange={(e) => setChecked(e.detail.checked)}
                disabled={disablecheck}
              />
            </IonItem>
          </div>
        </IonList>

        <IonCol className="ion-padding">
          <IonButton
            shape="round"
            expand="block"
            type="submit"
            color="success"
            onClick={() => setShowAlert1(true)}
          >
            Create an Account
            <IonIcon slot="start" icon={personAddSharp} />
          </IonButton>
        </IonCol>
        <IonAlert
          isOpen={showAlert1}
          onDidDismiss={() => setShowAlert1(false)}
          cssClass="my-custom-class"
          header={"Do you want to create an account?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                history.replace({
                  pathname: `/mdashboard`,
                });
              },
            },
            {
              text: "OK",
              handler: () => {                
                  addcompanyuser();                
                  setShowAlert2(true);
              },
            },
          ]}
        />
        <IonAlert
          isOpen={showAlert2}
          onDidDismiss={() => setShowAlert2(false)}
          cssClass="my-custom-class"
          header={"Do you want additional Account"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                history.replace({
                  pathname: `/mdashboard`,
                });
              },
            },
            {
              text: "OK",
              handler: () => {},
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default AddCompanyUser;
