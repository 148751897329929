import React from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Login from "./pages/Login";
import MDashboard from "./pages/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import Counsellingnew from "./pages/ConsultCounselling/Counselling";
import Medicalconsult from "./pages/ConsultCounselling/Medicalconsult";
import Schedulemenu from "./pages/ConsultCounselling/Schedmenu";
import SchedConsult from "./pages/ConsultCounselling/SchedConsult";

import Complist from "./pages/CompanyList/CompanyList";
import AddComp from "./pages/CompanyList/AddCompany";
import AddCompUser from "./pages/CompanyList/AddCompanyUser";

import AdmCompdashboard from "./pages/CompanyList/AdmCompdashboard";
import Updatecomp from "./pages/CompanyList/UpdateCompanydetails";
import SummaryReport from "./pages/Adminpages/SummaryReport";
import GenerateReports from "./pages/Adminpages/GenerateReports";
import NameSymptoms from "./pages/Adminpages/NameSymptoms";
import Last10symp from "./pages/Adminpages/Last10symptoms";
import Emplasten from "./pages/Adminpages/EmployeeLastEncode";

import Request from "./pages/Adminpages/Request";
import RequestInfo from "./pages/Adminpages/RequestInfo";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

// import { calendar, personCircle, map, informationCircle } from "ionicons/icons";

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/login/:slug" component={Login} exact={true} />
        <Route path="/complist" component={Complist} exact={true} />
        <ProtectedRoute path="/request" component={Request} exact={true} />
        <ProtectedRoute
          path="/requestinfo"
          component={RequestInfo}
          exact={true}
        />
        <ProtectedRoute
          path="/updatecomp"
          component={Updatecomp}
          exact={true}
        />
        <ProtectedRoute
          path="/SummaryReport"
          component={SummaryReport}
          exact={true}
        />
        <ProtectedRoute
          path="/GenReports"
          component={GenerateReports}
          exact={true}
        />
        <ProtectedRoute
          path="/NSymptoms"
          component={NameSymptoms}
          exact={true}
        />

        <ProtectedRoute
          path="/last10symp"
          component={Last10symp}
          exact={true}
        />
        <ProtectedRoute
          path="/emplasten"
          component={Emplasten}
          exact={true}
        />
        <ProtectedRoute path="/addcomp" component={AddComp} />
        <ProtectedRoute path="/addcompuser" component={AddCompUser} />
        <ProtectedRoute path="/admindashboard" component={AdmCompdashboard} />

        <ProtectedRoute path="/mdashboard" component={MDashboard} />
        <ProtectedRoute path="/smenu" component={Schedulemenu} exact={true} />
        <ProtectedRoute path="/cnew" component={Counsellingnew} exact={true} />
        <ProtectedRoute
          path="/mconsult"
          component={Medicalconsult}
          exact={true}
        />
        <ProtectedRoute
          path="/sconsult"
          component={SchedConsult}
          exact={true}
        />
        <Route path="/" render={() => <Redirect to="/login/1" />} />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
