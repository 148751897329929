import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  IonItem,
  IonLabel,
  IonDatetime,
  IonRow,
  IonInput,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCheckbox,
  IonButton,
  IonIcon,
  IonAlert,
  IonButtons,
  IonBackButton,
  IonModal,
  IonText,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { logIn } from "ionicons/icons";
import Moment from "moment";
import Auth from "../components/Auth";
import Schedholder from "../components/Schedholder";
const customDayShortNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const AddEntry: React.FC<any> = ({ history }) => {
  const Datenow = Moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  // console.log(Datenow);


  const [selectedDate, setSelectedDate] = useState<string>(Datenow);
  const [temp, setTemp] = useState(0);
  const [bp, setBp] = useState("");

  const [cough, setCough] = useState(false);
  const [throat, setThroat] = useState(false);
  const [breath, setBreath] = useState(false);
  const [diarrhea, setDiarrhea] = useState(false);
  const [soreeyes, setSoreeyes] = useState(false);
  const [covidbuddy, setCovidbuddy] = useState(false);
  const [rashes, setRashes] = useState(false);

  const [pmall, setpmall] = useState(false);
  const [ppaleng, setppaleng] = useState(false);
  const [psimba, setpsimba] = useState(false);

  const [ptbus, setptbus] = useState(false);
  const [ptjeep, setptjeep] = useState(false);
  const [pttri, setpttri] = useState(false);

  const [colds, setcolds] = useState(false);
  const [ssmell, setssmell] = useState(false);
  const [staste, setstaste] = useState(false);
  const [ypyes, setypyes] = useState(false);
  const [yptext, setyptext] = useState("");

  const [visityes, setvisityes] = useState(false);
  const [visittext, setvisittext] = useState("");

  const isconsult = false;
  const iscounsel = false;
  const pulserate = "";
  const resprate = "";
  const [isErrorModalVisible, setisErrorModalVisible] = useState(false);
  const [ismodalcovidbuddy, setIsmodalcovidbuddy] = useState(false);

  const [ismodalsurvey, setismodalsurvey] = useState(false);
  useEffect(() => { }, [cough, throat, breath, diarrhea, soreeyes, covidbuddy]);

  function InsertNewData() {
    // setisLoading(true);
    fetch(Auth.globalwebhost + "/monitoringapp/newdata3", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        PK_user: Auth.PK_user,
        // password: Auth.password,
        date: selectedDate,
        temp: temp,
        bp: bp,
        cough: cough,
        throat: throat,
        breath: breath,
        diarrhea: diarrhea,
        soreeyes: soreeyes,
        rashes: rashes,
        covidbuddy: covidbuddy,
        isconsult: isconsult,
        iscounsel: iscounsel,
        pulserate: pulserate,
        resprate: resprate,
        pmall: pmall,
        ppaleng: ppaleng,
        psimba: psimba,
        ptbus: ptbus,
        ptjeep: ptjeep,
        pttri: pttri,
        colds: colds,
        ssmell: ssmell,
        staste: staste,
        ypyes: ypyes,
        yptext: yptext,
        visityes: visityes,
        visittext: visittext,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.result === 0) {
          setIsmodalcovidbuddy(false);
          setismodalsurvey(false);    
          if (
            temp >= 37.5 ||
            cough ||
            throat ||
            diarrhea ||
            soreeyes ||
            rashes ||
            breath ||
            colds ||
            ssmell ||
            staste === true
          ) {
            // const clvars = {
            //   schedtype: 0,
            //   input: "symptom/s reported",
            //   hr: false,
            //   trans: false,
            //   house: false,
            //   safe: false,
            //   psych: false,
            // };

            const schedtype = "0";
            const input = "symptom/s reported";
            const hr = false;
            const trans = false;
            const house = false;
            const safe = false;
            const psych = false;

            const cb = () => {
              history.push({
                pathname: `/sconsult`,
                // state: clvars,
              });
            };

            Schedholder.saveschedule(
              cb,
              schedtype,
              input,
              hr,
              trans,
              house,
              safe,
              psych
            );
          } else {
            history.push({
              pathname: `/smenu`,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function RenderAlert() {
    return (
      <IonGrid>
        <IonModal isOpen={ismodalcovidbuddy} backdropDismiss={false}>
          <IonHeader>
            <IonToolbar className="tabcolor">
              <IonTitle>Covid Exposure</IonTitle>
              <IonRow slot="end"></IonRow>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid className="ion-text-sm-justify ion-padding-horizontal">
              <IonRow>
                <IonCol>
                  <IonText>
                    {" "}
                    History of Exposure to Known COVID-19 Case 14 days before
                    the onset of signs and symptoms:{" "}
                  </IonText>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="ion-text-center">
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={covidbuddy}
                      onIonChange={(e) =>
                        setCovidbuddy((covidbuddy) => !covidbuddy)
                      }
                    />
                    <IonText>
                      I have Exposure to Known COVID CASE Without Wearing PPE
                    </IonText>
                  </IonItem>
                  <IonButton
                    shape="round"
                    type="button"
                    color="success"
                    onClick={() => setismodalsurvey(true)}
                  >
                    Next
                    <IonIcon slot="start" icon={logIn} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>

        <IonAlert
          isOpen={isErrorModalVisible}
          onDidDismiss={() => setisErrorModalVisible(false)}
          header={"Check Temperature Input"}
          message={"Your temperature input is required."}
          buttons={["OK"]}
        />
      </IonGrid>
    );
  }

  function RenderAlert2() {
    return (
      <IonGrid>
        <IonModal isOpen={ismodalsurvey} backdropDismiss={false}>
          <IonHeader>
            <IonToolbar className="tabcolor">
              <IonTitle>Survey</IonTitle>
              <IonRow slot="end"></IonRow>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid className="ion-text-sm-justify ion-padding-horizontal">
              <form>
                <IonRow>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>
                          <b>
                            {" "}
                            Please check if you have been in the ff. places
                            yesterday?
                          </b>
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonItem>
                        <IonCheckbox
                          slot="start"
                          checked={pmall}
                          onIonChange={(e) => setpmall((pmall) => !pmall)}
                        />
                        <IonText>Mall (SM,Divimart,Ultimart,etc)</IonText>
                      </IonItem>
                      <IonItem>
                        <IonCheckbox
                          slot="start"
                          checked={ppaleng}
                          onIonChange={(e) => setppaleng((ppaleng) => !ppaleng)}
                        />
                        <IonText>
                          Palengke (Bayan,Shopping Mall, Wet Market)
                        </IonText>
                      </IonItem>
                      <IonItem>
                        <IonCheckbox
                          slot="start"
                          checked={psimba}
                          onIonChange={(e) => setpsimba((psimba) => !psimba)}
                        />
                        <IonText>Simbahan</IonText>
                      </IonItem>
                    </IonCard>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>
                          <b>
                            {" "}
                            Aside from Work and Home, Have you been to other
                            places yesterday? (Indicate occasion/reason, then
                            location)
                          </b>
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonItem>
                        <IonCheckbox
                          slot="start"
                          checked={ypyes}
                          onIonChange={(e) => setypyes((ypyes) => !ypyes)}
                        />
                        <IonText>
                          <h3>If yes, When and Where (input below)</h3>
                        </IonText>
                      </IonItem>
                      <IonItem hidden={ypyes === true ? false : true}>
                        <IonInput
                          placeholder="(ex: Birthday : Alaminos , Kasal : Tiaong )"
                          onInput={(e: any) =>
                            setyptext(e.target.value.toUpperCase())
                          }
                          value={yptext.toUpperCase()}
                        ></IonInput>
                      </IonItem>
                    </IonCard>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>
                          <b>
                            {" "}
                            Did you have any Visitors yesterday from other
                            places?
                          </b>
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonItem>
                        <IonCheckbox
                          slot="start"
                          checked={visityes}
                          onIonChange={(e) => setvisityes((ypyes) => !ypyes)}
                        />
                        <IonText>
                          <h3>
                            If yes, Indicate Place of origin (input below)
                          </h3>
                        </IonText>
                      </IonItem>
                      <IonItem hidden={visityes === true ? false : true}>
                        <IonInput
                          placeholder="(NCR, Laguna)"
                          onInput={(e: any) =>
                            setvisittext(e.target.value.toUpperCase())
                          }
                          value={visittext.toUpperCase()}
                        ></IonInput>
                      </IonItem>
                    </IonCard>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>
                          <b> Did you use public transportation yesterday?</b>
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonItem>
                        <IonCheckbox
                          slot="start"
                          checked={ptbus}
                          onIonChange={(e) => setptbus((ptbus) => !ptbus)}
                        />
                        <IonLabel>Bus</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonCheckbox
                          slot="start"
                          checked={ptjeep}
                          onIonChange={(e) => setptjeep((ptjeep) => !ptjeep)}
                        />
                        <IonLabel>Jeep</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonCheckbox
                          slot="start"
                          checked={pttri}
                          onIonChange={(e) => setpttri((pttri) => !pttri)}
                        />
                        <IonLabel>Tricycle</IonLabel>
                      </IonItem>
                    </IonCard>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol className="ion-text-center">
                    <IonButton
                      shape="round"
                      type="reset"
                      color="success"
                      onClick={InsertNewData}
                    >
                      Submit
                      <IonIcon slot="start" icon={logIn} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </form>
            </IonGrid>
          </IonContent>
        </IonModal>
      </IonGrid>
    );
  }

  function checkcondition() {
    if (temp <= 0 || temp > 50) {
      setisErrorModalVisible(true);
    } else {
      setIsmodalcovidbuddy(true);
      // console.log(selectedDate);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/mdashboard" />
          </IonButtons>
          <IonTitle>New Data</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <form>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel>
                    <b>Date and Time</b>
                  </IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD, DDD, HH:mm"
                    pickerFormat="YYYY-MM-DD"
                    dayShortNames={customDayShortNames}
                    value={selectedDate}
                    doneText={"set"}
                    onIonChange={(e) =>
                      setSelectedDate(
                        Moment(new Date(e.detail.value!)).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      )
                    }
                  ></IonDatetime>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem color="light" className="itemlistyle">
                  <IonLabel position="floating">Temperature °C</IonLabel>
                  <IonInput
                    name="Temperature"
                    onIonChange={(e: any) => setTemp(e.target.value)}
                    type="number"
                    spellCheck={false}
                    required
                    clearInput
                    min="0"
                    max="42"
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem color="light" className="itemlistyle">
                  <IonLabel position="floating">Blood Pressure</IonLabel>
                  <IonInput
                    name="Blood Pressure"
                    onIonChange={(e: any) => setBp(e.target.value)}
                    type="tel"
                    spellCheck={false}
                    required
                    clearInput
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>
                      <b>Let us know if you developed symptoms</b>
                    </IonCardTitle>
                  </IonCardHeader>
                </IonCard>

                <IonCard>
                  {/* <IonCardSubtitle>Symptoms: <IonIcon slot="start" icon={addCircle} /></IonCardSubtitle> */}

                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={cough}
                      onIonChange={(e) => setCough((cough) => !cough)}
                    />
                    <IonLabel>
                      <b>I have Cough</b>
                    </IonLabel>
                  </IonItem>

                  <IonCardContent>
                    An act that clears throat and breathing passages from
                    irritants. Serious cough are those with colored phlegm,
                    fever, wheezing and persisted for days. These need
                    professional attention.
                    <br></br> <br></br>
                    Isang pagkilos ng katawan na kini-clear ang lalamunan at
                    daluyan ng hangin sa mga irritants. Ang malubhang ubo ay
                    yaong may kulay na dahak, lagnat, paghuni ng hinga, at
                    tuloy-tuloy
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={throat}
                      onIonChange={(e) => setThroat((throat) => !throat)}
                    />
                    <IonLabel>
                      <b>I have Sore Throat</b>
                    </IonLabel>
                  </IonItem>

                  <IonCardContent>
                    Pain, scratchiness, or irritation of the throat that often
                    worsens when you swallow.
                    <br></br> <br></br>
                    Makati o iritasyon ng lalamunan at masakit sa paglunok.
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={breath}
                      onIonChange={(e) => setBreath((breath) => !breath)}
                    />
                    <IonLabel>
                      <b>I am experiencing Shortness of Breath</b>
                    </IonLabel>
                  </IonItem>

                  <IonCardContent>
                    An uncomfortable condition that makes it difficult to fully
                    get air into your lungs.
                    <br></br> <br></br>
                    Kondisyon na mahirap makakuha ng hangin sa baga.
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={diarrhea}
                      onIonChange={(e) => setDiarrhea((diarrhea) => !diarrhea)}
                    />
                    <IonLabel>
                      <b>I have Diarrhea</b>
                    </IonLabel>
                  </IonItem>

                  <IonCardContent>
                    Condition of having at least three (3) loose or watery bowel
                    movements in a day.
                    <br></br> <br></br>
                    Kondisyon na may tatlong (3) beses na malambot o matubig na
                    pagdudumi sa isang araw.
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={soreeyes}
                      onIonChange={(e) => setSoreeyes((soreeyes) => !soreeyes)}
                    />
                    <IonLabel>
                      <b>I have Sore Eyes</b>
                    </IonLabel>
                  </IonItem>

                  <IonCardContent>
                    Red, Swollen and Itchy of the eyes.
                    <br></br> <br></br>
                    Namumula , Namamaga at Makati ang mata.
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={rashes}
                      onIonChange={(e) => setRashes((rashes) => !rashes)}
                    />
                    <IonLabel>
                      <b>I have Skin Rashes</b>
                    </IonLabel>
                  </IonItem>

                  <IonCardContent>
                    Painful red or purple lesions that typically emerge on
                    fingers or toes
                    <br></br> <br></br>
                    Masakit na pula o lilang mga sugat na karaniwang lumilitaw
                    sa mga daliri o daliri ng paa
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={colds}
                      onIonChange={(e) => setcolds((colds) => !colds)}
                    />
                    <IonText>
                      <b>I have Colds</b>
                    </IonText>
                  </IonItem>

                  <IonCardContent>
                    A cold is a contagious upper respiratory infection that
                    affects your nose, throat, sinuses and trachea
                    <br></br> <br></br>
                    Ang sipon ay isang nakakahawang impeksyon sa itaas na
                    respiratory na nakakaapekto sa iyong ilong, lalamunan, sinus
                    at trachea
                  </IonCardContent>
                </IonCard>

                <IonCard hidden={colds === true ? false : true}>
                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={ssmell}
                      onIonChange={(e) => setssmell((ssmell) => !ssmell)}
                    />
                    <IonText>
                      <b>Wala ng pangamoy. (No sense of smell anymore)</b>
                    </IonText>
                  </IonItem>

                  <IonItem>
                    <IonCheckbox
                      slot="start"
                      checked={staste}
                      onIonChange={(e) => setstaste((staste) => !staste)}
                    />
                    <IonText>
                      <b>Wala ng panlasa. (No sense of taste anymore)</b>
                    </IonText>
                  </IonItem>
                </IonCard>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonButton
                  className="ion-margin"
                  type="button"
                  color="success"
                  onClick={checkcondition}
                >
                  Next
                  <IonIcon slot="start" icon={logIn} />
                </IonButton>
                {RenderAlert()}
                {RenderAlert2()}
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AddEntry;
