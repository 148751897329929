import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonCheckbox,
  IonTextarea,
  IonButton,
  IonIcon,
  IonButtons,
  IonBackButton,
} from "@ionic/react";

import React, { useState } from "react";
import { logIn } from "ionicons/icons";
import Schedholder from "../../components/Schedholder";

const Counsellingnew: React.FC<any> = ({ history, location }) => {
  // const [isConsellingModalVisible, setIsConsellingModalVisible] = useState(
  //   false
  // );
  const [hrconcerns, sethrconcerns] = useState(false);
  const [trans, settrans] = useState(false);
  const [housing, sethousing] = useState(false);
  const [safety, setsafety] = useState(false);
  const [psycho, setpsycho] = useState(false);

  const [oconcerns, setoconcerns] = useState("");

  const schedtype = "1";
  const input = oconcerns;

  function loadconfirmation() {
    const cb = () => {
      history.push({
        pathname: `/sconsult`,
        // state: clvars,
      });
    };

    Schedholder.saveschedule(
      cb,
      schedtype,
      input,
      hrconcerns,
      trans,
      housing,
      safety,
      psycho
    );

    // const loc = location.state
    // const clvars = {
    //   schedtype: 1,
    //   input: oconcerns,
    //   hr: hrconcerns,
    //   trans: trans,
    //   house: housing,
    //   safe: safety,
    //   psych: psycho,
    // };
    // history.push({
    //   pathname: `/sconsult`,
    //   state: clvars,
    // });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/smenu" />
          </IonButtons>
          <IonTitle> Counselling </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLabel>Kindly Select the related concern:</IonLabel>
              <IonItem>
                <IonCheckbox
                  slot="start"
                  checked={hrconcerns}
                  onIonChange={(e) =>
                    sethrconcerns((hrconcerns) => !hrconcerns)
                  }
                />
                <IonLabel>HR Concers</IonLabel>
              </IonItem>
              <IonItem>
                <IonCheckbox
                  slot="start"
                  checked={trans}
                  onIonChange={(e) => settrans((trans) => !trans)}
                />
                <IonLabel>Transportation</IonLabel>
              </IonItem>
              <IonItem>
                <IonCheckbox
                  slot="start"
                  checked={housing}
                  onIonChange={(e) => sethousing((housing) => !housing)}
                />
                <IonLabel>Housing</IonLabel>
              </IonItem>
              <IonItem>
                <IonCheckbox
                  slot="start"
                  checked={safety}
                  onIonChange={(e) => setsafety((safety) => !safety)}
                />
                <IonLabel>Safety</IonLabel>
              </IonItem>
              <IonItem>
                <IonCheckbox
                  slot="start"
                  checked={psycho}
                  onIonChange={(e) => setpsycho((psycho) => !psycho)}
                />
                <IonLabel>Psychological</IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Others, Specify</IonLabel>
                <IonTextarea
                  value={oconcerns}
                  onIonChange={(e) => setoconcerns(e.detail.value!)}
                ></IonTextarea>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonButton
                className="ion-margin"
                type="submit"
                color="success"
                onClick={() => loadconfirmation()}
              >
                Submit
                <IonIcon slot="start" icon={logIn} />
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Counsellingnew;
