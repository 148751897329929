import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonCard,
  IonCardHeader,

  IonCardTitle,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { RefresherEventDetail } from "@ionic/core";
import Auth from "../components/Auth";
import { Pie } from "react-chartjs-2";


interface Message {
  PK_nursestation: number;
  stationname: string;

  PK_usertemp: number;
  temp: number;
  bp: number;
  datetemp: Date;

  cough: string;
  throat: string;
  breath: string;
  soreeyes: string;
  covidbuddy: string;
  rashes: string;
}

interface Cdatamonitoring {
  PK_usertemp: number;
  number_of_usertemp: number;
  number_of_fever: number;
  number_of_cough: number;
  number_of_throat: number;
  number_of_breath: number;
  number_of_diarrhea: number;
  number_of_soreeyes: number;
  number_of_covidbuddy: number;
  number_of_rashes: number;
}

const MonitoringSheet: React.FC<any> = () => {
  async function fetchall() {
    // setisloading(true);
    fetch(Auth.globalwebhost + "/monitoringapp/loadmonitoringsheet", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        PK_user: Auth.PK_user,
        password: Auth.Password,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // setisloading(false);
        // setrefreshing(false);
        setDataSource(responseJson);
      })
      .catch((error) => {
        // seterror(error);
        // setisloading(false);
      });
  }

  async function fetchchartdata() {
    // setisloading(true);
    fetch(Auth.globalwebhost + "/monitoringapp/chartdatamonitoring", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        PK_user: Auth.PK_user,
      }),
    })
      .then((response) => response.json())
      .then((responseJson1) => {
        setDataSource1(responseJson1);
      })
      .catch((error) => {
        // seterror(error);
        // setisloading(false);
      });
  }

  const [DataSource, setDataSource] = useState<Message[]>([]);
  const [DataSource1, setDataSource1] = useState<Cdatamonitoring[]>([]);

  const options = {
    title: {
      display: true,
      text: "Symptoms for the past 30 Inputs",
    },
    responsive: true,
    maintainAspectRatio: true,

    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         suggestedMin: 0,
    //         suggestedMax: 70
    //       }
    //     }
    //   ]
    // }
  };

  const chartDataConfig = {
    labels: [
      "Breath",
      "Cough",
      "Covid Buddy",
      "Diarrhea",
      "Fever",
      "Rashes",
      "Sore Eyes",
      "Sore Throat",
    ],
    datasets: [
      {
        data: [
          DataSource1.map((d) => d.number_of_breath),
          DataSource1.map((d) => d.number_of_cough),
          DataSource1.map((d) => d.number_of_covidbuddy),
          DataSource1.map((d) => d.number_of_diarrhea),
          DataSource1.map((d) => d.number_of_fever),
          DataSource1.map((d) => d.number_of_rashes),
          DataSource1.map((d) => d.number_of_soreeyes),
          DataSource1.map((d) => d.number_of_throat),
        ],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#a3987d",
          "#74371e",
          "#9232fd",
          "#63fd85",
          "#f38a86",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#a3987d",
          "#74371e",
          "#9232fd",
          "#63fd85",
          "#f38a86",
        ],
      },
    ],
  };
  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      fetchall();
      fetchchartdata();
      // console.log(DataSource);
      event.detail.complete();
    }, 2000);
  }

  useEffect(() => {

    fetchall()
    fetchchartdata()

    // setInterval( function() { fetchall();},30000)
    // setInterval( function() { fetchchartdata();},30000)


  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          {/* <IonButtons slot="start">
          <IonBackButton defaultHref="/login" />
        </IonButtons> */}
          <IonTitle className="ion-margin">Monitoring Sheet</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid>
          <IonCard>
            <IonRow>
              <IonCol>
                <IonCardHeader>
                  <IonCardTitle>Temperature | Blood Pressure</IonCardTitle>
                </IonCardHeader>
                <IonList>
                  {DataSource.map((m) => (
                    <IonItem lines="none" key={m.PK_usertemp}>
                      <IonLabel>
                        <div style={{ color: m.temp >= 38 ? "red" : "default" }}>
                          <h1>
                            {m.temp}|{m.bp}
                          </h1>
                          <h3>{m.datetemp}</h3>
                          <h3>
                            {m.cough === "1" ? "| Cough " : ""}
                            {m.throat === "1" ? "| Sore Throat " : ""}
                            {m.breath === "1" ? "| Shortness of breath " : ""}
                            {m.soreeyes === "1" ? "| Sore Eyes " : ""}
                            {m.rashes === "1" ? "| Rashes " : ""}
                            {m.covidbuddy === "1" ? "| Exposure to Covid " : ""}
                          </h3>
                        </div>
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              </IonCol>

              <IonCol>
                <Pie data={chartDataConfig} options={options} />
              </IonCol>

            </IonRow>
          </IonCard>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MonitoringSheet;
