import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  IonLabel,
  IonRow,
  IonButton,
  IonIcon,
} from "@ionic/react";

import React from "react";
import { closeCircle, medkit, medkitOutline } from "ionicons/icons";

const Schedmenu: React.FC<any> = ({ history }) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonTitle> Schedule for Consultation </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="ion-text-left ion-margin-start ion-padding">
          <IonLabel position="floating">
            Do you want to request for a medical consult or counseling?
          </IonLabel>
          <IonRow>
            <IonCol>
              <IonButton
                className="ion-margin"
                type="submit"
                color="success"
                expand="full"
                shape="round"
                onClick={() =>
                  history.replace({
                    pathname: `/mconsult`,
                  })
                }
              >
                Medical Consult
                <IonIcon slot="start" icon={medkit} />
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton
                className="ion-margin"
                type="submit"
                color="success"
                expand="full"
                shape="round"
                onClick={() =>
                  history.replace({
                    pathname: `/cnew`,
                  })
                }
              >
                Counselling
                <IonIcon slot="start" icon={medkitOutline} />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                className="ion-margin"
                type="submit"
                color="success"
                expand="full"
                shape="round"
                // size="large"
                onClick={() =>
                  history.replace({
                    pathname: `/mdashboard`,
                  })
                }
              >
                Not Now
                <IonIcon slot="start" icon={closeCircle} />
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Schedmenu;
