import {
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import React from "react";
import { Redirect } from "react-router-dom";
import { calendar, add, logOut } from "ionicons/icons";
import ProtectedRoute from "../components/ProtectedRoute";
import Auth from "../components/Auth";

import MList from "./MonitoringSheet";
// import AEntry from "./AddEntry";
import AEntry from "./AddEntry2";

import PDetails from "./PatientDetails";
const PatientDashboard: React.FC<any> = ({ history }) => {
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path="/mdashboard" to="/mdashboard/mlist" />
        {/*
            Using the render method prop cuts down the number of renders your components will have due to route changes.
            Use the component prop when your component depends on the RouterComponentProps passed in automatically.
          */}

        <ProtectedRoute
          path="/mdashboard/mlist/"
          component={MList}
          exact={true}
        />
        <ProtectedRoute
          path="/mdashboard/aentry/"
          component={AEntry}
          exact={true}
        />
        <ProtectedRoute path="/pdashboard/pDetails/" component={PDetails} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton selected tab="schedule" href="/mdashboard/mlist">
          <IonIcon icon={calendar} />
          <IonLabel>Monitoring List</IonLabel>
          {/* <IonBadge>6</IonBadge> */}
        </IonTabButton>

        <IonTabButton tab="speakers" href="/mdashboard/aentry">
          <IonIcon icon={add} />
          <IonLabel>Add Entry </IonLabel>
        </IonTabButton>

        {/* <IonTabButton tab="map">
          <IonIcon icon={personCircle} />
          <IonLabel>Account</IonLabel>
        </IonTabButton> */}

        <IonTabButton
          tab="Logout"
          onClick={() =>
            Auth.logout(() => {
              history.replace("/");
            })
          }
        >
          <IonIcon icon={logOut} />
          <IonLabel>Logout</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default PatientDashboard;
