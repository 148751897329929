import {
  IonContent,
  IonRow,
  IonHeader,
  IonAvatar,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonList,
  IonIcon,
 
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { arrowForwardSharp } from "ionicons/icons";
import Auth from "../../components/Auth";
interface Message {
  PK_tempcompany: number;
  compname: string;

  contactperson: string;
  contactno: number;
  emailadd: string;
  address: string;

  compicon: string;
}
const CompanyList: React.FC<any> = ({ history }) => {
  const [DataSource, setDataSource] = useState<Message[]>([]);

  function fetchall() {
    fetch(Auth.globalwebhost + "/monitoringapp/companylist", {
      method: "POST",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);

        setDataSource(responseJson);
      });
  }

  useEffect(() => {
    fetchall();
  },[]);

  // const TempCompanyCallback = () => {
  //   history.replace({
  //     pathname: `/login`,
  //   });
  // };

  return (
    <IonPage>
      <IonHeader className="ion-no-boarder">
        <IonToolbar className="tabcolor">
          <IonRow>
            <IonTitle>Company Lists</IonTitle>

            <IonItem className="tabcolor"></IonItem>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          {DataSource.map((m) => (
            <IonItem
              key={m.PK_tempcompany}
              onClick={() => {
                // Auth.tempcompany(TempCompanyCallback, m.PK_tempcompany);.
                history.replace({
                  pathname: `/login/${m.PK_tempcompany}`,
                });
              }}
            >
              <IonAvatar slot="start">
                <img
                  src={m.compicon}
                  alt="ion"
                  style={{ objectFit: "initial" }}
                  // className="imgcontainer"
                ></img>
              </IonAvatar>

              <IonLabel>{m.compname}</IonLabel>
              <IonIcon slot="end" icon={arrowForwardSharp} />
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default CompanyList;
