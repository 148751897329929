import {
  IonContent,
  IonRow,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonIcon,
  IonText,
  IonInput,
  IonButton,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
import React, { useState } from "react";
import { personAddSharp } from "ionicons/icons";
import Auth from "../../components/Auth";
const AddCompany: React.FC<any> = ({ history }) => {
  const [compname, setcompname] = useState("");
  const [contactp, setcontactp] = useState("");
  const [contactn, setcontactn] = useState("");
  const [emailadd, setemailadd] = useState("");
  const [address, setaddress] = useState("");
  const [compicon, setcompicon] = useState({ preview: "", raw: "" });

  const addcompy = async (e: any) => {
    const fetchAsBlob = (url: any) =>
      fetch(url).then((response) => response.blob());
    const convertBlobToBase64 = (blob: Blob) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });

    const blobUrl = compicon.preview;

    const blob64 = await fetchAsBlob(blobUrl)
      .then(convertBlobToBase64)
      .then((base64: any) => {
        return base64;
      });

    console.log(blob64);

    fetch(Auth.globalwebhost + "/monitoringapp/addcompany", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        compname: compname,
        contactp: contactp,
        contactn: contactn,
        emailadd: emailadd,
        address: address,
        compicon: blob64,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        console.log("Successfully Inserted");

        Auth.globalcompany(globalcompanycallback, responseJson);
      });
    const globalcompanycallback = () => {
      history.replace({
        pathname: `/addcompuser`,
      });
    };
  };
  return (
    <IonPage>
      <IonHeader className="ion-no-boarder">
        <IonToolbar className="tabcolor">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/admindashboard" />
          </IonButtons>
          <IonRow>
            <IonTitle className="ion-text-center"> New Company</IonTitle>
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <IonGrid className="ion-no-margin ion-no-padding">
          <IonList lines="full">
            <IonItem>
              <IonLabel position="stacked">
                {" "}
                Company Name <IonText color="danger">*</IonText>
              </IonLabel>
              <IonInput
                required
                type="text"
                clearInput
                onIonChange={(e: any) => setcompname(e.target.value)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">
                {" "}
                Contact Person: <IonText color="danger">*</IonText>
              </IonLabel>
              <IonInput
                required
                type="text"
                clearInput
                onIonChange={(e: any) => setcontactp(e.target.value)}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">
                {" "}
                Contact No: <IonText color="danger">*</IonText>
              </IonLabel>
              <IonInput
                required
                type="number"
                clearInput
                onIonChange={(e: any) => setcontactn(e.target.value)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">
                {" "}
                Email Address <IonText color="danger">*</IonText>
              </IonLabel>
              <IonInput
                required
                type="email"
                clearInput
                onIonChange={(e: any) => setemailadd(e.target.value)}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Address</IonLabel>
              <IonInput
                required
                type="text"
                clearInput
                onIonChange={(e: any) => setaddress(e.target.value)}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="fixed">Add Logo:</IonLabel>
              <input
                type="file"
                src=""
                // style={{ display: "none" }}
                onChange={(e: any) =>
                  setcompicon({
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0],
                  })
                }
              />
            </IonItem>
          </IonList>
          <IonCol>
            <IonRow>
              <img
                src={compicon.preview}
                alt="Empty Logo"
                width="300"
                height="300"
              />
            </IonRow>
          </IonCol>

          <IonCol>
            <IonRow>
              <IonButton
                expand="block"
                type="submit"
                color="success"
                onClick={addcompy}
              >
                Create an Account
                <IonIcon slot="start" icon={personAddSharp} />
              </IonButton>
            </IonRow>
          </IonCol>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AddCompany;
