class Schedholder {
  constructor() {
    this.schedtype = "";
    this.input = "";
    this.hr = false;
    this.trans = false;
    this.house = false;
    this.safe = false;
    this.psych = false;
  }
  saveschedule(cb ,schedtype, input, hr, trans, house, safe, psych) {
   
    this.schedtype = schedtype;
    this.input = input;
    this.hr = hr;
    this.trans = trans;
    this.house = house;
    this.safe = safe;
    this.psych = psych;
    cb()
  }
}

export default new Schedholder();
