import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonIcon,
} from "@ionic/react";

import React, { useState, useEffect } from "react";
import { businessOutline, personAddOutline, folderOutline,browsersOutline, bookOutline } from "ionicons/icons";
import Auth from "../../components/Auth";

const AdmCompdashboard: React.FC<any> = ({ history, match }) => {
  useEffect(() => {
    if (Auth.is_superadmin === "1") {
      setshowaddcomp(true);
    } else {
      setshowaddcomp(false);
    }

    // return function () {};
  }, []);
  const [showaddcomp, setshowaddcomp] = useState(false);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonTitle> Company Admin Dashboard </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="ion-text-left ion-margin-start ion-padding">
          {/* <IonLabel position="floating">
              Do you want to request for a medical consult or counseling?
            </IonLabel> */}
          <IonRow>
            <IonCol>
              <IonButton
                className="ion-margin"
                type="submit"
                color="success"
                expand="full"
                shape="round"

              
                onClick={() =>                
                  history.replace({
                    pathname: `/addcompuser`,
                  })
                }
              >
                Add Company User
                <IonIcon slot="start" icon={personAddOutline} />
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton
                className="ion-margin"
                type="submit"
                color="success"
                expand="full"
                shape="round"
                onClick={() =>
                  history.replace({
                    pathname: `/updatecomp`,
                  })
                }
              >
                Update Company Details
                <IonIcon slot="start" icon={folderOutline} />
              </IonButton>
            </IonCol>
          </IonRow>
          <div style={{ display: showaddcomp ? "block" : "none" }}>
            <IonRow>
              <IonCol>
                <IonButton
                  className="ion-margin"
                  type="submit"
                  color="success"
                  expand="full"
                  shape="round"
                  // size="large"
                  onClick={() =>
                    history.replace({
                      pathname: `/addcomp`,
                    })
                  }
                >
                  Add Company
                  <IonIcon slot="start" icon={businessOutline} />
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
          <IonRow>
            <IonCol>
              <IonButton
                className="ion-margin"
                type="submit"
                color="success"
                expand="full"
                shape="round"
                // size="large"
                onClick={() =>
                  history.replace({
                    pathname: `/mdashboard`,
                  })
                }
              >
                Monitoring Dashboard
                <IonIcon slot="start" icon={browsersOutline} />
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton
                className="ion-margin"
                type="submit"
                color="success"
                expand="full"
                shape="round"
                // size="large"
                onClick={() =>
                  history.replace({
                    pathname: `/SummaryReport`,
                  })
                }
              >
                Summary Report 
                <IonIcon slot="start" icon={bookOutline} />
              </IonButton>
            </IonCol>
          </IonRow>

        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AdmCompdashboard;
