import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  IonLabel,
  IonRow,
  IonButtons,
  IonBackButton,
  IonItem,
  IonCheckbox,
  IonDatetime,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { logIn } from "ionicons/icons";
import React, { useState, useEffect } from "react";
import Auth from "../../components/Auth";
import Moment from "moment";
import DataTable from "react-data-table-component";

interface Request {
  PK_user: number;
  PK_usersched: number;
  username: string;
  FK_company: number;
  message: string;
  cough: string;
  throat: string;
  breath: String;
  diarrhea: String;
  isconsult: String;
  iscounsel: String;
  datetemp: string;
  cl_hr: number;
  cl_transpo: number;
  cl_house: number;
  cl_safety: number;
  cl_psycho: number;
}

interface Message {
  PK_user: number;
  username: string;
  temp: number;
  cough: number;
  throat: number;
  breath: number;
  diarrhea: number;
  soreeyes: number;
  covidbuddy: number;
  rashes: number;
  isconsult: number;
  iscounsel: number;
}

const columns = [
  {
    name: "Username",
    selector: "username",
    sortable: true,
  },
  {
    name: "Date Temp",
    selector: "datetemp",
    sortable: true,
    right: true,
  },
  {
    name: "Temperature",
    selector: "temp",
    sortable: true,
    right: true,
  },
  {
    name: "Cough",
    selector: "cough",
    sortable: true,
    right: true,
  },
  {
    name: "Throat",
    selector: "throat",
    sortable: true,
    right: true,
  },
  {
    name: "Breath",
    selector: "breath",
    sortable: true,
    right: true,
  },
  {
    name: "Diarrhea",
    selector: "diarrhea",
    sortable: true,
    right: true,
  },

  {
    name: "Sore Eyes",
    selector: "soreeyes",
    sortable: true,
    right: true,
  },

  {
    name: "ETCP",
    selector: "covidbuddy",
    sortable: true,
    right: true,
  },

  {
    name: "Skin Rashes",
    selector: "rashes",
    sortable: true,
    right: true,
  },
  {
    name: "Consultation",
    selector: "isconsult",
    sortable: true,
    right: true,
  },
  {
    name: "Counselling",
    selector: "iscounsel",
    sortable: true,
    right: true,
  },
];

const RequestInfo: React.FC<any> = ({ history }) => {
  const [DataSource, setDataSource] = useState<Request[]>([]);
  const [DataSource1, setDataSource1] = useState<Message[]>([]);
  // const [PK_usersched, setPK_usersched] = useState(52);

  const Datenow = Moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
  const [selectedDate, setSelectedDate] = useState<string>(Datenow);

  const [schedule, setschedule] = useState(false);
  const [mistake, setmistake] = useState(false);
  const [done, setdone] = useState(false);
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
        fontSize: "11",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "14",
        fontWeight: "Bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  function fetchall() {
    fetch(Auth.globalwebhost + "/monitoringapp/detailuser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        PK_user: Auth.FK_user,
        FK_company: Auth.FK_company,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setDataSource1(responseJson);
      });

    if (Auth.isparcc === 1) {
      fetch(Auth.globalwebhost + "/monitoringapp/requestinfo", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          schedtype: 1,
          FK_company: Auth.FK_company,
          PK_usersched: Auth.ispkuser,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setDataSource(responseJson);
        });
    } else if (Auth.isparcc === 2) {
      fetch(Auth.globalwebhost + "/monitoringapp/requestinfo", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          schedtype: 0,
          FK_company: Auth.FK_company,
          PK_usersched: Auth.ispkuser,
        }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          setDataSource(responseJson);
        });
    }
  }

  function insertstatus() {
    fetch(Auth.globalwebhost + "/monitoringapp/checkstatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        PK_usersched: Auth.ispkuser,
        FK_user_process: Auth.PK_user,
        datesched: selectedDate,
        schedule: schedule,
        mistake: mistake,
        done: done,
      }),
    }).then((response) => response.json());

    history.replace({
      pathname: `/SummaryReport`,
    });
  }

  useEffect(() => {
    fetchall();
  }, [schedule, mistake, done]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/request" />
          </IonButtons>
          <IonTitle className="ion-margin">Employee Information</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form>
          {DataSource.map((m) => (
            <IonGrid
              key={m.PK_user}
              className="ion-padding ion-margin container  "
            >
              <IonRow>
                <IonCol>
                  <IonLabel>Username: {m.username} </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel>Message: {m.message} </IonLabel>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <IonLabel>
                    Concern: {m.cl_hr} {m.cl_transpo}, {m.cl_house},{" "}
                    {m.cl_safety}, {m.cl_psycho}
                  </IonLabel>
                </IonCol>
              </IonRow>

              <IonItem>
                <IonCheckbox
                  name="chk2"
                  slot="start"
                  checked={schedule}
                  onIonChange={(e) => setschedule((schedule) => !schedule)}
                />
                <IonLabel>Schedule</IonLabel>
              </IonItem>
              {schedule ? (
                <IonItem>
                  <IonLabel>Set Date:</IonLabel>
                  <IonDatetime
                    displayFormat="YYYY-MM-DD, HH:mm"
                    // dayShortNames={customDayShortNames}
                    value={selectedDate}
                    onIonChange={(e) => setSelectedDate(e.detail.value!)}
                  ></IonDatetime>
                </IonItem>
              ) : null}

              <IonItem>
                <IonCheckbox
                  name="chk1"
                  slot="start"
                  checked={mistake}
                  onIonChange={(e) => setmistake((mistake) => !mistake)}
                />
                <IonLabel>Mistake</IonLabel>
              </IonItem>

              <IonItem>
                <IonCheckbox
                  name="chk3"
                  slot="start"
                  checked={done}
                  onIonChange={(e) => setdone((done) => !done)}
                />
                <IonLabel>Done</IonLabel>
              </IonItem>
              <IonCol>
                <IonRow>
                  <IonButton
                    shape="round"
                    type="reset"
                    color="success"
                    onClick={insertstatus}
                  >
                    Submit
                    <IonIcon slot="start" icon={logIn} />
                  </IonButton>
                </IonRow>
              </IonCol>
            </IonGrid>
          ))}

          <IonRow className="ion-padding">
            <IonCol>
              <DataTable
                data={DataSource1}
                columns={columns}
                pagination={true}
                highlightOnHover={true}
                customStyles={customStyles}
                noHeader={true}
              />
            </IonCol>
          </IonRow>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default RequestInfo;
