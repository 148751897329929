import {
    IonContent,
    IonRow,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonButtons,
    IonBackButton,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import Auth from "../../components/Auth";

import MUIDataTable from "mui-datatables";


interface datasauce {
    PK_user: number;
    username: string;
    temp: number;
    cough: number;
    throat: number;
    breath: number;
    diarrhea: number;
    soreeyes: number;
    covidbuddy: number;
    rashes: number;
    isconsult: number;
    iscounsel: number;
}


const EmployeeLastEncode: React.FC<any> = ({ history }) => {

    const [DataSource, setDataSource] = useState<datasauce[]>([]);

   



    function fetchall() {
        Promise.all([
            fetch(Auth.globalwebhost + "/monitoringapp/empchecker", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },               
            }),
        ])
            .then(([res1]) =>
                Promise.all([res1.json()])
            )
            .then(([responseJson1]) => {
                setDataSource(responseJson1);
            });
    }

    useEffect(() => {
        fetchall();
        // eslint-disable-next-line        
    }, []);


    const columns12 = [
         
        {
          name: "fullname",
          label: "EmployeeName",       
        },
        {
          name: "sectcode",
          label: "Section",       
        },
        {
            name: "lastdatetemp",
            label: "Last Date Entry",       
        },
        {
            name: "temp",
            label: "Temperature",       
        },
    
      ];
    
      const Options2 = {
        filterType: "dropdown",
        print: false,
        sort: true,
        sortDescFirst: true,
        sortThirdClickReset: true,
        selectableRowsOnClick: true,     
      };

    return (
        <IonPage>
            <IonHeader className="ion-no-boarder">
                <IonToolbar className="tabcolor">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/summaryreport" />
                    </IonButtons>
                    <IonRow>
                        <IonTitle className="ion-text-center"> Employee Last Entry Log</IonTitle>
                    </IonRow>
                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding">
                <IonGrid>
                        <MUIDataTable
                    title={"SPCMC EMPLOYEE LIST"}
                    data={DataSource}
                    columns={columns12}
                    options={Options2}
                />
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default EmployeeLastEncode;
