import {
  IonContent,
  IonRow,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  IonItem,
  IonLabel,
  IonIcon,
  IonButtons,
  IonBackButton,
  IonDatetime,
  IonList,
  IonCard,
  IonButton,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import Auth from "../../components/Auth";
// import DataTable from "react-data-table-component";
import Moment from "moment";
import { Doughnut } from "react-chartjs-2";
import {
  logIn,
  person,
  peopleOutline,
  peopleCircleOutline,
  flame,
  personRemoveOutline,
  chatboxSharp,
  chatbubble,
  nutritionOutline,
  removeCircleOutline,
  calendar,
  handLeft,
  eyeOffOutline,
  accessibilityOutline,
  snowOutline,
  businessOutline,
  fileTrayFullOutline,
  busOutline,
  cogOutline,
  nuclearOutline,
  fastFood,
  roseOutline,
  helpBuoyOutline,
  sadOutline,
  printSharp,
  refreshOutline,
  checkboxOutline,
} from "ionicons/icons";

import MUIDataTable from "mui-datatables";

// import { Dashboard } from "@material-ui/icons";

interface Message {
  PK_user: number;
  username: string;
  temp: number;
  cough: number;
  throat: number;
  breath: number;
  diarrhea: number;
  soreeyes: number;
  covidbuddy: number;
  rashes: number;
  isconsult: number;
  iscounsel: number;
}

interface Symptoms {
  PK_usertemp: number;
  number_of_usertemp: number;
  number_of_fever: number;
  number_of_cough: number;
  number_of_throat: number;
  number_of_breath: number;
  number_of_diarrhea: number;
  number_of_soreeyes: number;
  number_of_covidbuddy: number;
  number_of_rashes: number;
  number_of_isconsult: number;
  number_of_iscounsel: number;
  number_of_colds: number;
  number_of_pmall: number;
  number_of_ppaleng: number;
  number_of_psimba: number;
  number_of_ptbus: number;
  number_of_ptjeep: number;
  number_of_pttri: number;
  number_of_ssmell: number;
  number_of_staste: number;
}

interface alluser {
  PK_user: number;
  number_of_PK_user: number;
}
interface symptreport {
  PK_user: number;
  number_of_PK_usersched: number;
}

const SummaryReport: React.FC<any> = ({ history }) => {

  const [DataSource, setDataSource] = useState<Message[]>([]);
  const [CSympSource, setCSympSource] = useState<Symptoms[]>([]);
  const [Alluser, setAlluser] = useState<alluser[]>([]);
  const [Sympreport, setSympreport] = useState<symptreport[]>([]);

  const Datenow = Moment(new Date()).format("YYYY-MM-DD");

  const [Datetimeval, setDatetimeval] = useState<string>(Datenow);


  function aaddress(holder: any) {
    setDatetimeval(holder)
    fetchall()
  }

  function fetchall() {
    Promise.all([
      fetch(Auth.globalwebhost + "/monitoringapp/symptomsreport", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Datenow: Moment(Datetimeval).format("YYYY-MM-DD"),
          // FK_company: Auth.FK_company,
        }),
      }),
      fetch(Auth.globalwebhost + "/monitoringapp/countsymptoms1", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Datenow: Moment(Datetimeval).format("YYYY-MM-DD"),
          FK_company: Auth.FK_company,
        }),
      }),

      fetch(Auth.globalwebhost + "/monitoringapp/allusercount", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          FK_company: Auth.FK_company,
        }),
      }),

      fetch(Auth.globalwebhost + "/monitoringapp/summaryreport2", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Datenow: Moment(Datetimeval).format("YYYY-MM-DD"),
          FK_company: Auth.FK_company,
        }),
      }),
    ])
      .then(([res1, res2, res3, res4]) =>
        Promise.all([res1.json(), res2.json(), res3.json(), res4.json()])
      )
      .then(([responseJson1, responseJson2, responseJson3, responseJson4]) => {
        setSympreport(responseJson1);

        setCSympSource(responseJson2);

        setAlluser(responseJson3);

        setDataSource(responseJson4);

        // console.log(responseJson4)

        // console.log(data)
      });
  }

  useEffect(() => {
    fetchall();

    // eslint-disable-next-line
  }, []);

  const cctestcallback = () => {
    history.replace({
      pathname: `/request`,
    });
  };

  const NSymptomscallback = () => {
    history.replace({
      pathname: `/NSymptoms`,
    });
  };
  const options = {
    title: {
      display: true,
      text: "Symptoms",
    },
    responsive: true,
    maintainAspectRatio: true,

    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         suggestedMin: 0,
    //         suggestedMax: 70
    //       }
    //     }
    //   ]
    // }
  };

  const chartDataConfig = {
    labels: [
      "Breath",
      "Cough",
      "Covid Buddy",
      "Diarrhea",
      "Fever",
      "Rashes",
      "Sore Eyes",
      "Sore Throat",
      "Colds",
      "SofSmell",
      "SofTaste",
    ],
    datasets: [
      {
        data: [
          CSympSource.map((d) => d.number_of_breath),
          CSympSource.map((d) => d.number_of_cough),
          CSympSource.map((d) => d.number_of_covidbuddy),
          CSympSource.map((d) => d.number_of_diarrhea),
          CSympSource.map((d) => d.number_of_fever),
          CSympSource.map((d) => d.number_of_rashes),
          CSympSource.map((d) => d.number_of_soreeyes),
          CSympSource.map((d) => d.number_of_throat),
          CSympSource.map((d) => d.number_of_colds),
          CSympSource.map((d) => d.number_of_ssmell),
          CSympSource.map((d) => d.number_of_staste),
        ],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#a3987d",
          "#74371e",
          "#9232fd",
          "#63fd85",
          "#f38a86",
          "#E0105C",
          "#199468",
          "#04E092",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#a3987d",
          "#74371e",
          "#9232fd",
          "#63fd85",
          "#f38a86",
          "#E0105C",
          "#199468",
          "#04E092",
        ],
      },
    ],
  };

  const columns12 = [
    {
      name: "PK_user",
      label: "PK_user",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
        // customBodyRender: (value: any, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {

        //     const nf = new Intl.NumberFormat('en-US', {
        //       style: 'currency',
        //       currency: 'USD',
        //       minimumFractionDigits: 2,
        //       maximumFractionDigits: 2
        //     });

        //     return nf.format(value);

        // }
      },
    },
    {
      name: "fullname",
      label: "EmployeeName",
      options: {
        filter: true,
        sort: true,


        // customBodyRender: (value: any, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {

        //     const nf = new Intl.NumberFormat('en-US', {
        //       style: 'currency',
        //       currency: 'USD',
        //       minimumFractionDigits: 2,
        //       maximumFractionDigits: 2
        //     });

        //     return nf.format(value);

        // }
      },
    },
    {
      name: "sectcode",
      label: "Section",
      options: {
        filter: true,
        sort: true,

        // customBodyRender: (value: any, tableMeta: { columnIndex: any; }, updateValue: (arg0: any) => any) => {

        //     const nf = new Intl.NumberFormat('en-US', {
        //       style: 'currency',
        //       currency: 'USD',
        //       minimumFractionDigits: 2,
        //       maximumFractionDigits: 2
        //     });

        //     return nf.format(value);

        // }
      },
    },
    {
      name: "temp",
      label: "Temperature",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "cough",
      label: "Cough",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "throat",
      label: "Sore Throat",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "breath",
      label: "Breath",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "diarrhea",
      label: "Diarrhea",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "soreeyes",
      label: "Soreeyes",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "rashes",
      label: "Rashes",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "colds",
      label: "Colds",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "covidbuddy",
      label: "ETCP",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ssmell",
      label: "SofSmell",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "staste",
      label: "SofTaste",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pmall",
      label: "Mall",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "ppaleng",
      label: "Palengke",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "psimba",
      label: "Simbahan",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "ptbus",
      label: "Bus",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "ptjeep",
      label: "Jeep",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pttri",
      label: "Tricycle",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "yptext",
      label: "Other Places",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "visittex",
      label: "Other Visitors",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const Options2 = {
    filterType: "dropdown",
    print: false,
    sort: true,
    sortDescFirst: true,
    sortThirdClickReset: true,
    selectableRowsOnClick: true,

    // onRowSelectionChange: (rowData:any, rowState:any) => {

    //   console.log(rowData, rowState);

    //   // return (

    //   //   <TableRow>

    //   //     <TableCell colSpan={rowData.length}>

    //   //       Custom expandable row option. Data: {JSON.stringify(rowData)}

    //   //     </TableCell>

    //   //   </TableRow>

    //   // );

    // }

    // onRowSelectionChange: (e:any) => {

    // }
    // onRowClick: () => {
    //   console.log("click!");
    // },
    // setRowProps: () => ({
    //   onMouseUp: (e:any) => {
    //     console.log(e.PK_user);
    //   }
    // })
    // onRowSelectionChange: (currentRowsSelected: any, allRowsSelected : any ) => {

    //   let selectedItems = allRowsSelected.map(item => {
    //     return DataSource[item.index];
    // })
    // dispatch(setSelectedCallOrders({selectedCallOrders: selectedItems}))
    // },

    // onRowClick: (rowData, rowState) => {
    //   console.log(rowData, rowState);
    // },
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-boarder">
        <IonToolbar className="tabcolor">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/admindashboard" />
          </IonButtons>
          <IonRow>
            <IonTitle className="ion-text-center"> Summary Report </IonTitle>
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <IonGrid className="ion-margin ion-no-padding">
          {CSympSource.map((m) => (
            <IonList key={m.PK_usertemp}>
              <IonRow>
                <IonCol>
                  <IonCard>
                    <IonItem>
                      <IonLabel>Reporting Date:</IonLabel>
                      <IonIcon slot="start" icon={calendar} />
                      <IonDatetime
                        displayFormat="YYYY-MM-DD"
                        value={Datetimeval}
                        doneText={"SET"}
                        onIonChange={(e) => aaddress(e.detail.value!)}
                      ></IonDatetime>

                      <IonButton
                        shape="round"
                        type="submit"
                        color="success"
                        onClick={fetchall}
                      >
                        Load
                        <IonIcon slot="start" icon={refreshOutline} />
                      </IonButton>
                    </IonItem>

                    {Alluser.map((s) => (
                      <IonList key={s.PK_user}>
                        <IonItem key={s.PK_user}>
                          <IonLabel>User Count: {s.number_of_PK_user}</IonLabel>
                          <IonIcon slot="start" icon={person} />
                        </IonItem>

                        <IonItem>
                          <IonLabel>
                            No. Submission:{" "}
                            {s.number_of_PK_user - m.number_of_usertemp}
                          </IonLabel>

                          <IonIcon slot="start" icon={logIn} />
                        </IonItem>
                      </IonList>
                    ))}

                    {Sympreport.map((t) => (
                      <IonList key={t.number_of_PK_usersched}>
                        <IonItem>
                          <IonLabel>Submitted: {m.number_of_usertemp}</IonLabel>
                          <IonIcon slot="start" icon={peopleCircleOutline} />
                        </IonItem>
                        <IonItem>
                          <IonLabel>
                            % Normal: ({t.number_of_PK_usersched}){" "}
                            {(
                              (t.number_of_PK_usersched /
                                m.number_of_usertemp) *
                              100
                            ).toFixed(2)}
                            %
                          </IonLabel>
                          <IonIcon slot="start" icon={peopleOutline} />
                        </IonItem>
                      </IonList>
                    ))}

                    <IonItem onClick={() => Auth.cctest(cctestcallback, 1)}>
                      <IonLabel>Counselling Requests:</IonLabel>
                      <IonIcon slot="start" icon={chatboxSharp} />
                    </IonItem>
                    <IonItem onClick={() => Auth.cctest(cctestcallback, 2)}>
                      <IonLabel>Consultation Requests:</IonLabel>
                      <IonIcon slot="start" icon={chatbubble} />
                    </IonItem>
                    <IonItem>
                      <IonLabel onClick={() => history.replace({ pathname: `/GenReports`, })}> Generate Reports</IonLabel>
                      <IonIcon slot="start" icon={printSharp} />
                    </IonItem>
                  </IonCard>
                </IonCol>

                <IonCol>
                  <IonCard>
                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "Fever")}>Fever: {m.number_of_fever}</IonLabel>
                      <IonIcon slot="start" icon={flame} />
                    </IonItem>
                    <IonItem>

                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "cough")}> Cough: {m.number_of_cough}</IonLabel>
                      <IonIcon slot="start" icon={removeCircleOutline} />
                    </IonItem>
                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "SoreThroat")}>Sore Throat: {m.number_of_throat}</IonLabel>
                      <IonIcon slot="start" icon={nutritionOutline} />
                    </IonItem>
                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "Breath")}>
                        Difficult in Breathing: {m.number_of_breath}
                      </IonLabel>
                      <IonIcon slot="start" icon={sadOutline} />
                    </IonItem>
                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "Diarrhea")}>Diarrhea: {m.number_of_diarrhea}</IonLabel>
                      <IonIcon slot="start" icon={personRemoveOutline} />
                    </IonItem>
                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "SoreEyes")}>Sore Eyes: {m.number_of_soreeyes}</IonLabel>
                      <IonIcon slot="start" icon={eyeOffOutline} />
                    </IonItem>

                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "rashes")}>Skin Rashes: {m.number_of_rashes}</IonLabel>
                      <IonIcon slot="start" icon={handLeft} />
                    </IonItem>

                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "colds")}>Colds: {m.number_of_colds}</IonLabel>
                      <IonIcon slot="start" icon={snowOutline} />
                    </IonItem>
                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "ETCP")}>
                        Exposure to Covid Patient: {m.number_of_covidbuddy}
                      </IonLabel>
                      <IonIcon slot="start" icon={accessibilityOutline} />
                    </IonItem>
                  </IonCard>
                </IonCol>

                <IonCol>
                  <IonCard>
                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "Smell")}>Sense of Smell: {m.number_of_ssmell}</IonLabel>
                      <IonIcon slot="start" icon={roseOutline} />
                    </IonItem>

                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "Staste")} >Sense of Taste: {m.number_of_staste}</IonLabel>
                      <IonIcon slot="start" icon={fastFood} />
                    </IonItem>
                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "Mall")}>Mall: {m.number_of_pmall}</IonLabel>
                      <IonIcon slot="start" icon={businessOutline} />
                    </IonItem>

                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "Palengke")}>Palengke: {m.number_of_ppaleng}</IonLabel>
                      <IonIcon slot="start" icon={helpBuoyOutline} />
                    </IonItem>

                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "Simbahan")}>Simbahan: {m.number_of_psimba}</IonLabel>
                      <IonIcon slot="start" icon={fileTrayFullOutline} />
                    </IonItem>

                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "Bus")}>Bus: {m.number_of_ptbus}</IonLabel>
                      <IonIcon slot="start" icon={busOutline} />
                    </IonItem>

                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "Jeep")}>Jeep: {m.number_of_ptjeep}</IonLabel>
                      <IonIcon slot="start" icon={cogOutline} />
                    </IonItem>

                    <IonItem>
                      <IonLabel onClick={() => Auth.nsymptoms(NSymptomscallback, "Tricycle")}>Tricycle: {m.number_of_pttri}</IonLabel>
                      <IonIcon slot="start" icon={nuclearOutline} />
                    </IonItem>

                    <IonItem>
                      <IonLabel onClick={() => history.replace({ pathname: `/emplasten`, })}> Check Employee Last Entry</IonLabel>
                      <IonIcon slot="start" icon={checkboxOutline} />
                    </IonItem>
                  </IonCard>
                </IonCol>

                <IonCol>
                  <Doughnut data={chartDataConfig} options={options} />
                </IonCol>
              </IonRow>
            </IonList>
          ))}
          {/* 
          <IonRow className="ion-padding">
            <IonCol>
              <DataTable
                data={DataSource}
                columns={columns}
                pagination={true}
                highlightOnHover={true}
                customStyles={customStyles}
                noHeader={true}
              />
            </IonCol>
          </IonRow> */}

          <MUIDataTable
            title={"SPCMC MONITORING LIST"}
            data={DataSource}
            columns={columns12}
            options={Options2}
          />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SummaryReport;
