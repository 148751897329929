import {
  IonContent,
  IonIcon,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonRow,
  IonGrid,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonModal,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { logIn, checkboxOutline, logoGooglePlaystore } from "ionicons/icons";
import "./Login.css";
import Auth from "../components/Auth";
import { Redirect } from "react-router-dom";

interface Message {
  PK_tempcompany: number;
  compname: string;

  contactperson: string;
  contactno: number;
  emailadd: string;
  address: string;

  compicon: string;
}

const Login: React.FC<any> = ({ history, match }) => {
  // const [globalwebhost] = useState("https://www.spcmcph.com");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // const [isLoading, setisLoading] = useState(true);
  // const [DataSource, setDataSource] = useState<Message[]>([]);

  const [showModal, setShowModal] = useState(false);
  const [textoutput, settextoutput] = useState("Loading");
  // const [darkmode, setDarkmode] = useState(false);

  // console.log(isLoading)
  // function enableDarkTheme(shouldenable: boolean) {
  //   document.body.classList.toggle("dark", shouldenable);
  // }

  const fetchall = () => {
    // console.log(match.params.slug);
    // setisLoading(true);
    Promise.all([
      fetch(Auth.globalwebhost + "/monitoringapp/message").then((resp) => {
        return resp.text();
      }),

      // fetch(Auth.globalwebhost + "/monitoringapp/loadcompany.php", {
      //   method: "POST",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     PK_tempcompany: 1,
      //   }),
      // }),
    ])
      .then(([text]) => Promise.all([text]))
      .then(([text]) => {
        settextoutput(text);

        // setDataSource(responseJson1);
      });
  };

  useEffect(() => {
    fetchall();

    // if (darkmode === true) {
    //   const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
    //   enableDarkTheme(prefersDark.matches);
    //   prefersDark.addListener((mediaQuery) =>
    //     enableDarkTheme(mediaQuery.matches)
    //   );
    // } else {
    //   const prefersDark = window.matchMedia("(prefers-color-scheme: primary)");
    //   enableDarkTheme(prefersDark.matches);
    //   prefersDark.addListener((mediaQuery) =>
    //     enableDarkTheme(mediaQuery.matches)
    //   );
    // }
    // eslint-disable-next-line
  }, []);

  const RenderModals = () => {
    return (
      <IonModal isOpen={showModal} backdropDismiss={false}>
        <IonHeader>
          <IonToolbar className="tabcolor">
            <IonTitle>Notification</IonTitle>
            <IonRow slot="end"></IonRow>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid className="ion-text-sm-justify ion-padding-horizontal">
            <IonRow>
              <IonCol>
                <pre style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                  {textoutput}
                </pre>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="ion-text-center">
                <IonButton
                  className="ion-margin "
                  type="submit"
                  color="success"
                  onClick={() => setShowModal(false)}
                >
                  I Understand / Naiintindihan Ko
                  <IonIcon slot="start" icon={checkboxOutline} />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    );
  };

  const UserLoginFunction = () => {
    Auth.login(UserLoginCallback, username, password);
  };

  const UserLoginCallback = () => {
    if (Auth.is_admin === "1" && Auth.PK_user > 0) {
      setShowModal(true);
      history.replace({
        pathname: `/admindashboard`,
      });
    } else if (Auth.is_admin === "0" && Auth.PK_user > 0) {
      setShowModal(true);
      // redirect
      history.replace({
        pathname: `/mdashboard`,
      });
    } else {
      return (
        <Redirect
          to={{
            pathname: "/complist",
          }}
        />
      );
    }
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-boarder">
        <IonToolbar className="tabcolor">
          <IonRow>
            <IonTitle>SPCMC Monitoring App</IonTitle>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="maingrid ">
          <form>
            <IonRow>
              <IonCol>
                <img
                  src="assets/icon/logo.png"
                  alt="ion"
                  className="imgcontainer"
                ></img>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem color="light" className="itemlistyle">
                  <IonLabel position="floating">Username</IonLabel>
                  <IonInput
                    name="username"
                    onIonChange={(e: any) => setUsername(e.target.value)}
                    type="text"
                    spellCheck={false}
                    autocapitalize="off"
                    required
                    clearInput
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem color="light" className="itemlistyle">
                  <IonLabel position="floating">Password</IonLabel>
                  <IonInput
                    name="password"
                    type="password"
                    onIonChange={(e: any) => setPassword(e.target.value)}
                    clearInput
                  ></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-center">
                <IonButton
                  shape="round"
                  type="reset"
                  color="success"
                  onClick={UserLoginFunction}
                >
                  Login
                  <IonIcon slot="start" icon={logIn} />
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-center">
                <IonLabel>
                  This app is exclusive to SPCMC employees only.
                </IonLabel>
              </IonCol>
            </IonRow>
            {/* <IonRow>
          <IonCol className="ion-text-center">
            <IonLabel>              
            <IonIcon slot="start" icon={arrowDownOutline} />      
              Please Update the latest APP Version                                       
            </IonLabel>         
          </IonCol>                
        </IonRow> */}

            <IonRow>
              <IonCol className="ion-text-center">
                <IonButton
                  shape="round"
                  type="submit"
                  color="success"
                  href="https://play.google.com/store/apps/details?id=com.spcmc.monitoringapp"
                >
                  Install on your Android Device
                  <IonIcon slot="start" icon={logoGooglePlaystore} />
                </IonButton>
              </IonCol>
            </IonRow>
            {RenderModals()}
          </form>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
