import {
    IonContent,
    IonRow,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonButtons,
    IonBackButton,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import Auth from "../../components/Auth";

import DataTable from "react-data-table-component";


interface datasauce {
    PK_user: number;
    username: string;
    temp: number;
    cough: number;
    throat: number;
    breath: number;
    diarrhea: number;
    soreeyes: number;
    covidbuddy: number;
    rashes: number;
    isconsult: number;
    iscounsel: number;
}


const Last10symptoms: React.FC<any> = ({ history }) => {

    const [DataSource, setDataSource] = useState<datasauce[]>([]);

    const customStyles = {
        rows: {
            style: {
                minHeight: "72px", // override the row height
                fontSize: "8",
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",
                fontSize: "12",
                fontWeight: "Bold",
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",
            },
        },
    };


    const columns = [
        {
            name: "Employee Name",
            selector: "fullname",
            sortable: true,
            wrap: true,
        },
        {
            name: "Section",
            selector: "sectcode",
            sortable: true,
            right: true,
        },
        {
            name: "Temp",
            selector: "temp",
            sortable: true,
            right: true,
            wrap: true,
        },
        {
            name: "Date Temp",
            selector: "datetemp",
            sortable: true,
            right: true,
        },
        {
            name: "Temperature",
            selector: "temp",
            sortable: true,
            right: true,
        },
        {
            name: "Cough",
            selector: "cough",
            sortable: true,
            right: true,
        },
        {
            name: "Throat",
            selector: "throat",
            sortable: true,
            right: true,
        },
        {
            name: "Breath",
            selector: "breath",
            sortable: true,
            right: true,
        },
        {
            name: "Diarrhea",
            selector: "diarrhea",
            sortable: true,
            right: true,
        },

        {
            name: "Sore Eyes",
            selector: "soreeyes",
            sortable: true,
            right: true,
        },

        {
            name: "ETCP",
            selector: "covidbuddy",
            sortable: true,
            right: true,
        },

        {
            name: "Skin Rashes",
            selector: "rashes",
            sortable: true,
            right: true,
        },
        {
            name: "Colds",
            selector: "colds",
            sortable: true,
            right: true,
        },
        {
            name: "Sense of Smell",
            selector: "ssmell",
            sortable: true,
            right: true,
        },
        {
            name: "Sense of Taste",
            selector: "staste",
            sortable: true,
            right: true,
        },
    ];

    function fetchall() {
        Promise.all([
            fetch(Auth.globalwebhost + "/monitoringapp/detailuser", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    PK_user: Auth.sympkuser,
                    FK_company: Auth.FK_company,
                }),
            }),
        ])
            .then(([res1]) =>
                Promise.all([res1.json()])
            )
            .then(([responseJson1]) => {
                setDataSource(responseJson1);
            });
    }

    useEffect(() => {
        fetchall();
        // eslint-disable-next-line        
    }, []);



    return (
        <IonPage>
            <IonHeader className="ion-no-boarder">
                <IonToolbar className="tabcolor">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/summaryreport" />
                    </IonButtons>
                    <IonRow>
                        <IonTitle className="ion-text-center"> Last 10 Inputs of the Employee</IonTitle>
                    </IonRow>
                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding">
                <IonGrid>
                    <DataTable
                        data={DataSource}
                        columns={columns}
                        pagination={true}
                        highlightOnHover={true}
                        noHeader={true}
                        customStyles={customStyles}
                        expandableInheritConditionalStyles={true}
                        direction="auto"

                    />
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Last10symptoms;
